<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="custOrder-container">
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">客户订单总数</div>
        <div class="item-num">{{ totalNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">昨日新增客户订单数</div>
        <div class="item-num">{{ yesterdayNew || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">今日新增客户订单数</div>
        <div class="item-num">{{ todayNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本周新增客户订单数</div>
        <div class="item-num">{{ weekNew || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本月新增客户订单数</div>
        <div class="item-num">{{ monthNum || "0" }}</div>
      </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>订单名称/编号:</span>
        <el-input
          class="common-screen-input"
          placeholder="订单名称/编号"
          v-model="params.name"
          clearable 
          @clear="() => clearInput()"
        ></el-input>
      </div>
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          placeholder="请选择"
          @change="companyFun"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属店铺:</span>
         <el-select
          class="common-screen-input"
          v-model="params.shopId"
          placeholder="请搜索店铺"
          @change="commonFun"
          filterable
          clearable
          remote
          :remote-method="(val)=>{remoteMethod(val, 'shopList')}"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属客户:</span>
         <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="(val)=>{remoteMethod(val, 'custList')}"
        >
          <el-option
            v-for="item in custList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单阶段:</span>
        <el-select
          class="common-screen-input"
          v-model="params.orderStep"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['5']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => custOrderList()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <el-table class="common-table" :data="tableData">
      <el-table-column prop="order_no" label="订单编号" width="160x">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => getOrderDetail(scope.row)">{{scope.row.order_no || '--'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="订单名称"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="customer_name"
        label="所属客户"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="shop_names"
        label="所属店铺"
        width="160px"
      >
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.shop_names"
            class="item"
            effect="dark"
            :content="scope.row.shop_names"
            placement="bottom"
          >
            <div style="white-space: pre-line">
              {{ commonLineFeed(scope.row.shop_names) }}
            </div>
          </el-tooltip>
          <div v-else style="white-space: pre-line">
            {{ commonLineFeed(scope.row.shop_names) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="order_step_name"
        label="订单阶段"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="订单金额"
        :formatter="tableColumn"
        width="130px"
      ></el-table-column>
      <el-table-column label="服务时间" width="140px">
        <template slot-scope="scope">
          <div style="white-space: pre-line">
            {{
              `${DateTransform(scope.row.start_time)}\n${DateTransform(
                scope.row.end_time
              )}`
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        width="160px"
      >
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
            <div class="common-overflow-drop">{{scope.row.note}}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column label="操作" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => {addOrder(scope.row)}">新增订单</el-button>
          <el-button type="text" size="small" @click="() => addShop(scope.row)"
            >新增店铺</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="新增订单"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="cusOrderForm"
          :rules="cusOrderRules"
          ref="cusOrderForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="订单名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="cusOrderForm.name"
              placeholder="订单名称"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称" prop="custName">
            <el-input
              class="dialog-input"
              :value="cusOrderForm.custName"
              placeholder="客户名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="指派人" prop="toId">
            <el-select
              class="dialog-input"
              v-model="cusOrderForm.toId"
              placeholder="请选择指派人"
              filterable
            >
              <el-option
                v-for="item in assignPeople"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售机会金额" prop="price">
            <el-input
              type="number"
              class="dialog-input"
              v-model="cusOrderForm.price"
              placeholder="销售机会金额"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单开始时间" prop="startTime">
            <el-date-picker
              class="dialog-input"
              v-model="cusOrderForm.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订单结束时间" prop="endTime">
            <el-date-picker
              class="dialog-input"
              v-model="cusOrderForm.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="协议店铺数" prop="agreeShopNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="cusOrderForm.agreeShopNum"
              placeholder="协议店铺数"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="协议子账号数" prop="agreeSubNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="cusOrderForm.agreeSubNum"
              placeholder="协议子账号数"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="协议日均咨询量" prop="conslutDayNum">
            <el-input
              type="number"
              class="dialog-input"
              v-model="cusOrderForm.conslutDayNum"
              placeholder="协议单账号日均咨询量"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="cusOrderForm.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
          <el-form-item label="合同订单">
            <div class="orderFile-container">
              <div class="orderFileBtn">
                上传附件
                <input class="orderFile-input" type="file" @input="(e) => {orderFile(e)}">
              </div>
              上传文件及图片，大小不超过2MB
            </div>
            <div class="fileList-container">
              <div v-for="(item, index) in fileList.name" :key="index" class="fileList-item">
                <div class="item-name">
                  {{item}}
                </div>
                <div @click="() => {deleteFlie(index)}" class="deleteBtn">
                  删除
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => cusNewOrder('cusOrderForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <order-detail
      ref="saleLead"
      :userList="userList"
      :drawer="drawer"
      :assignPeople="assignPeople"
      :tableRow="tableRow"
      :adminInfo="adminInfo"
      :OrderInfo="OrderInfo"
      :followRecordList="followRecordList"
      :loading="loading"
      :fileList="filesList"
      @handleClose="handleClose"
      @getOrderDetail="getOrderDetail"
      @loadFun="loadFun"
      :title="'查看订单'"
    ></order-detail>
    <shop-order-add
      ref="cusListAdd"
      :tableRow="tableRow"
      :drawer="drawerShop"
      :platList="platList"
      :finishedList="custList"
      :userList="userList"
      @handleClose="handleCloseShop"
    ></shop-order-add>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import OrderDetail from "./children/orderDetail.vue";
import ShopOrderAdd from "./children/shopOrderAdd.vue";
import {
  custOrderList,
  getOrderDetail,
  orderAppoint,
  getPlatList,
  cusNewOrder
} from "../../service/customer.js";
import { upload } from "../../service/upload.js";
import { getUserList } from "../../service/manage.js";
import {
  commonDict,
  getShopList,
  getCustList,
  getRoleByType
} from "../../service/common";
import {
  tableColumn,
  DateTransform,
  commonLineFeed,
  Config
} from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
    OrderDetail,
    ShopOrderAdd
  },
  data() {
    return {
      fileList: [],
      filesList: [],
      cusOrderForm: {},
      dialogVisible: false,
      commonLineFeed,
      DateTransform,
      tableRow: "",
      adminInfo: "",
      OrderInfo: "",
      followRecordList: "",
      tableColumn,
      loading: false,
      platList: [],
      userList: [],
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "销售", isLink: false },
        { title: "新签客户订单", isLink: false },
      ],
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      assignPeople: [],
      comPeopleList: [],
      custDict: {},
      custList: [], // 所属客户下拉
      shopList: [], // 所属店铺下拉
      drawerShop: false, // 新增店铺弹窗
      params: {
        pageNum: 1,
        pageSize: 10
      },
      ruleForm: {
        // 修改密码
      },
      rules: {
        serviceId: [
          { required: true, message: "请选择负责人", trigger: "change" }
        ]
      },
      cusOrderRules: {
        // 校验规则
        name: [{ required: true, message: "请填写订单名称", trigger: "change" }],
        custName: [{ required: true, trigger: "change" }],
        toId: [{ required: true, message: "请选择指派人", trigger: "change" }],
        price: [{ required: true, message: "请填写金额", trigger: "change" }],
        startTime: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        }
      ],
      tableData: [],
      totalNum: 0,
      todayNum: 0,
      yesterdayNew: 0,
      weekNew: 0,
      monthNum: 0,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company // 所属公司下拉
    };
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    this.getPlatList();
    this.custOrderList();
    this.commonDict(5);
    // this.getCustList();
    // this.getShopList();
    this.getRoleByType(1);
    this.getRoleByType(2);
    this.getUserList();
  },
  methods: {
    remoteMethod (val, name) {
      console.log(val, name, '=============-=-=-=-=-=-=-=-=-')
      if (name == 'shopList') {
        this.getShopList(val)
      } else if (name == 'custList') {
        this.getCustList(val)
      }
    },
    clearInput () {
      // 筛选框清空-输入框
      this.custOrderList()
    },
    deleteFlie (i) {
      let fileList = {...this.fileList}
      fileList.name = fileList.name.filter((item,index)=>{
        return i != index
      })
      fileList.id = fileList.id.filter((item,index)=>{
        return i != index
      })
      fileList.url = fileList.url.filter((item,index)=>{
        return i != index
      })
      this.fileList = fileList
    },
    async orderFile (e) {
      let fileList = {...this.fileList}
      let file = e.target.files[0];
      let fileName = file.name;
      let resData = (await upload({ file: file })).data;
      fileList.name.push(fileName)
      fileList.id.push(resData.id)
      fileList.url.push(resData.filename)
      this.fileList = fileList
    },
    cusNewOrder(formName) {
      let cusOrderForm = { ...this.cusOrderForm };
      let fileList = { ...this.fileList };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (cusOrderForm.custName) {
            delete cusOrderForm.custName;
          }
          if (fileList && fileList.name) {
            cusOrderForm.filedName = fileList.name.join(',')
          }
          if (fileList && fileList.id) {
            cusOrderForm.filedIds = fileList.id.join(',')
          }
          if (fileList && fileList.url) {
            cusOrderForm.filedUrl = fileList.url.join(',')
          }
          await cusNewOrder(cusOrderForm);
          this.$message.success("新增订单成功");
          this.custOrderList();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addOrder (row) {
      this.cusOrderForm = {
        customerId: row.customer_id,
        custName: row.customer_name
      }
      this.fileList = {
        name: [],
        id: [],
        url: []
      }
      if (this.$refs["cusOrderForm"]) {
        this.$refs["cusOrderForm"].resetFields();
      }
      this.dialogVisible = true
    },
    loadFun(value) {
      this.loading = value;
    },
    async getUserList() {
      // 商务负责下拉，暂用员工列表
      let resData = (await getUserList({ pageNum: -1 })).data;
      this.userList = resData;
    },
    async getPlatList() {
      // 所属平台/类目
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    commonFun() {
      this.pageSizes = 10;
      this.pageNum = 1;
      this.custOrderList();
    },
    dialogAppoint(row) {
      let params = { ...this.ruleForm };
      this.ruleForm = Object.assign(row, params);
      this.ruleForm.customerId = row.customer_id;
      this.centerDialogVisible = true;
    },
    async orderAppoint(formName) {
      let params = { ...this.ruleForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await orderAppoint(params);
          this.$message.success("指派成功");
          this.centerDialogVisible = false;
          this.custOrderList();
        } else {
          return false;
        }
      });
    },
    async getRoleByType(id) {
      let resData = (await getRoleByType({}, id)).data;
      if (id == 1) {
        this.assignPeople = resData;
      } else if (id == 2) {
        this.comPeopleList = resData;
      }
    },
    async getOrderDetail(row) {
      // 获取订单详情
      this.drawer = true;
      this.loading = true;
      let resData = (await getOrderDetail({ orderId: row.id })).data;
      this.$refs["saleLead"].getNowStage(row, resData.customerOrderDetailVo);
      this.tableRow = row;
      this.adminInfo = resData.admin || "";
      this.OrderInfo = resData.customerOrderDetailVo || "";
      if(resData && resData.customerOrderDetailVo ) {
        let filedIdsArr = resData.customerOrderDetailVo.filedIds ? resData.customerOrderDetailVo.filedIds.split(',') : []
        let filedNameArr = resData.customerOrderDetailVo.filedName ? resData.customerOrderDetailVo.filedName.split(',') : []
        let filedUrlArr = resData.customerOrderDetailVo.filedUrl ? resData.customerOrderDetailVo.filedUrl.split(',') : []
        let newArrFile = []
        filedIdsArr.map((item, index)=>{
          newArrFile.push({name: filedNameArr[index], id: item, url: filedUrlArr[index]})
        })
        this.filesList = newArrFile
      }
      if (resData.list) {
        resData.list.map((item) => {
          let newArr = [];
          if (item.fileNames && item.fileUrl) {
            item.fileNames = item.fileNames.split(",");
            item.fileUrl = item.fileUrl.split(",");
            item.fileNames.map((itemFormat) => {
              let format = itemFormat.split(".")[itemFormat.split(".").length - 1];
              if (
                format == "bmp" ||
                format == "jpg" ||
                format == "png" ||
                format == "gif" ||
                format == "jpeg"
              ) {
                format = "img";
              }
              newArr.push(format);
            });
          }
          item.fileType = newArr;
        });
      }
      this.followRecordList = resData.list || "";
      this.loading = false;
    },
    resetBtn() {
      // 重置
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id //重置
      };
      this.custOrderList();
    },
    async getShopList(name) {
      // 所属店铺下拉
      let resData = (await getShopList({shopName: name})).data;
      this.shopList = resData;
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({customerName: name})).data;
      this.custList = resData;
    },
    async commonDict(type) {
      // 字典值
      let resData = (await commonDict({}, type)).data;
      this.custDict[`${type}`] = resData;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.custOrderList();
    },
    async custOrderList() {
      // 客户订单列表
      this.loading = true;
      let params = { ...this.params };
      let resData = (await custOrderList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.todayNum = resData.today;
      this.totalNum = resData.totalNum;
      this.monthNum = resData.month;
      this.yesterdayNew = resData.yesterday;
      this.weekNew = resData.week;
      this.loading = false;
    },
    editPassword(e) {
      // 修改密码弹窗
      this.centerDialogVisible = true;
      console.log(e);
    },
    handleClose() {
      this.drawer = false;
      this.custOrderList();
      // 关闭新增员工弹窗
      // done();
    },
    handleCloseShop() {
      this.drawerShop = false;
      this.custOrderList();
      // 关闭新增弹窗
      // done();
    },
    addShop(row) {
      //新增店铺
      this.drawerShop = true;
      if (row) {
        this.tableRow = row;
        this.$refs["cusListAdd"].getDataInfo("", row);
      }
    },
    companyFun() {
      //选择公司筛选
      this.custOrderList();
    }
  }
};
</script>
<style lang="less" scoped>
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
.orderFile-container {
    .orderFileBtn {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border:  1px solid rgba(220, 223, 230, 100);
      color: #666;
      background: #fff;
      text-align: center;
      font-size: 14px;
      position: relative;
      border-radius: 4px;
      display: inline-block;
      .orderFile-input {
        width: 100px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
.custOrder-container {
  /deep/ .el-button {
    white-space: normal;
    text-align: left;
    line-height: 20px;
  }
  text-align: left;
  .Summary-container {
    width: 100%;
    background-color: #fff;
    margin-bottom: 16px;
    position: relative;
    padding: 6px 0 25px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    &::after {
      display: block;
      content: " ";
      height: 1px;
      width: 120%;
      background-color: #e6e6e6;
      position: absolute;
      bottom: 0;
      left: -10%;
    }
    .item-border {
      border: 1px dashed #707070;
    }
    .Summary-item {
      width: 9%;
      height: 124px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 32px;

      .item-title {
        font-size: 18px;
        padding-top: 10px;
        color: #333;
      }
      .item-num {
        font-size: 30px;
        color: #1890ff;
        font-weight: 700;
      }
    }
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
